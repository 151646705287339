// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{
	/*
	$('#nav-open').click(function(e){
		e.preventDefault();

		$('body').addClass('is-nav-open');
	});

	$('#nav-close').click(function(e){
		e.preventDefault();

		$('body').removeClass('is-nav-open');
	});

	  sm: 576px,
	  md: 768px,
	  lg: 992px,
	  xl: 1200px

	*/


	$('#slider-events').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: '<button type="button" class="slick-prev"><span><i class="icon-angle-left"></i></span></button>',
		nextArrow: '<button type="button" class="slick-next"><span><i class="icon-angle-right"></i></span></button>',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	const autoCompleteJS = new autoComplete({
		selector: "#searchbar",
		submit:true,
		data: {
			src: async (query) => {
				try {
					// Fetch Data from external Source
					const source = await fetch(`/search/${query}`);
					// Data is array of `Objects` | `Strings`
					const data = await source.json();

					return data;
				} catch (error) {
					return error;
				}
			},
			// Data 'Object' key to be searched
			keys: ["title"]
		},
		resultsList: {
			maxResults: 5
		},
		resultItem: {
			element: (item, data) => {
				console.log(data);
				item.setAttribute("data-url", data.value.url);
			},
		},
		events: {
			list: {
				click: (event) => {
					window.location.href = event.target.getAttribute('data-url');
				}
			}
		}
	});


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('data-src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
